<template>
  <div>
    季度统计：<el-select v-model="value" placeholder="请选择季度" size="mini" @change="changeJd">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
  </div>
  <div id="jd" class="about" style="width:100%;height:320px;"></div>
  <el-row :gutter="50" type="flex" justify="center" align="middle" style="margin: 20px 0px 0px 0px">
    <el-col :span="8">
      <div class="box"><div class="countStyle">{{amount}}</div>不含税金额</div>
    </el-col>
    <el-col :span="7">
      <div class="box"><div class="countStyle">{{tax}}</div>税额</div>
    </el-col>
    <el-col :span="9">
      <div class="box"><div class="countStyle">{{count}}</div>已开发票</div>
    </el-col>
  </el-row>
</template>

<script type="text/javascript">
import * as echarts from 'echarts';
export default {
  props:{
    list:{
      type: Array,
      default: [],
    }
  },
  data(){
    return {
      options: [
          {
            value: '1',
            label: '第一季度',
          },
          {
            value: '2',
            label: '第二季度',
          },
          {
            value: '3',
            label: '第三季度',
          },
          {
            value: '4',
            label: '第四季度',
          },
      ],
      value: this.$store.getters.currQuarter,
      amount:0,
      tax:0,
      count:0
    }
  },
  mounted() {
  },
  methods:{
    init(list){
      this.flag = false
      this.flag = true
      this.$nextTick(() => {
          this.initChart(list)
      }) 
    },
    initChart(list){
      this.amount = '￥' + list.reduce((p,e)=>p+e.amount,0).toFixed(2);
      this.count = list.reduce((p,e)=>p+e.count,0) + '张';
      this.tax = '￥' + list.reduce((p,e)=>p+e.tax,0).toFixed(2);
      let data = list.map(v=>{return this.$fplx(v.fplxdm)+':'+ '￥'+v.amount.toFixed(2)})
      let seriesData = list.map(v=>{
        let amount = v.amount.toFixed(2)
        return {
          value: amount,
          name:this.$fplx(v.fplxdm) + ':'+ '￥' + amount
        }
      })
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('jd'));
      // 绘制图表
      myChart.setOption({
        tooltip: {},
        legend: {
          orient: "horizontal",
          y: "bottom",
          data: data
        },
        series: [
          {
            type: 'pie',
            data: seriesData,
            radius: '40%'
          }
        ]
      });
      window.onresize = function(){
        myChart.resize()
      }
    },
    changeJd(){
      this.$emit('success', this.value)
    },
  }
};
</script>
 
<style lang="less" scoped>
#main {
  height: 400px;
  width: 100%;
}
.content{
  color: gray;
  margin-bottom: 45px;
  margin-top: 12px;
}
.box{
  float: left;
  text-align: center;
  margin-left: 50px;
}
.countStyle{
  color: #6ABFBA;
  font-weight: 600;
}
</style>
