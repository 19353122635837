import request from '@/utils/request'

//更新公司信息
export function indexInvoice(data) {
    return request({
        url: 'api/v1/invoice/indexInvoice',
        method: 'post',
        data
    })
}
//税盘状态
export function invoiceStatus(data) {
    return request({
        url: 'api/v1/invoice/invoiceStatus',
        method: 'post',
        data
    })
}