<template>
 
</template>

<script>
import MonthInvoice from "./components/monthInvoice.vue";
import JdInvoice from "./components/jdInvoice.vue";
import { indexInvoice, invoiceStatus } from "@/api/homeIndex";
import { defineComponent  } from 'vue'
export default defineComponent({
  components:{ MonthInvoice, JdInvoice },
  name: "homeIndex",
  data(){
    return {
      title: '',
      list:[],
      list2:[],
      list3:[],
      data:[],//发票余量
    }
  },
  mounted(){
  },
  setup() {

  },
  created(){
    // this.getList()
  },
  methods:{
    xzFilter(n){
       if(n == "00"){
         return "非特殊企业"
       }else if(n == "01"){
         return "特殊企业"
       }else if(n == "05"){
         return "转登记纳税人"
       }else if(n == "08"){
         return "小规模纳税人"
       }
    },
    //初始化视图
    initMonth(e){
      this.listQuery.month = e
      this.getList()
    },
    initJd(e){
      this.listQuery.jd = e
      this.getList()
    },
    getList(){
      this.listQuery.comId = this.$store.getters['user/comInfo'].id
      indexInvoice(this.listQuery).then(res=>{
        this.list = res.data.data.info.monthSum
        this.list2 = res.data.data.info.jdSum
        this.list3 = res.data.data.info.ypList
        this.data =  this.list3.map(v=>{
          return {
            name:this.$fplx(v.fplxdm),
            value:v.syfs}
        })
        console.log(this.data);
        this.$refs.monthInvoice.init(this.list)
        this.$refs.jdInvoice.init(this.list2)
      })
    },
    connect(){
      invoiceStatus({comId:this.$store.getters['user/comInfo'].id}).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("连接正常")
        }
      })
    },
  }
})
</script>

<style lang="scss">
  .card-header {
    height: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .box-card {
    width: 30%;
  }
  .title{
    font-size: 16px;
    font-weight: 600;
  }
  .spbh{
    color: #6ABFBA;
    position: relative;
    float: right;
    top: -60px;
    span{
      margin-right: 10px; 
      margin-left: 10px;
      img{
        position: relative;
        top:7px
      }
    }
  }
</style>
<style>
.el-descriptions__label:not(.is-bordered-label){
  font-size: 14px;
  font-weight: 600;
  color: #2c3e50;
}
.el-descriptions__title {
  font-size: 18px;
}
i:hover{
  color: #6ABFBA;
}
.has-gutter{
  color: #fff !important;
}
</style>